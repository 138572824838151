import React, {FC, useContext, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {ChartsWidget7, ChartsWidget8} from '../../../_metronic/partials/widgets'
import {ProductContext, ProductReturn} from '../../GeneraleVariables'
import {ValueType} from 'rsuite/cjs/DateRangePicker/types'
import {subDays} from 'date-fns'
import {DateRange, usePeriod, UseWindowSize} from '../../modules/all-pages'
import {QueryFunctionContext, QueryKey, useQueries} from 'react-query'
import ChartDashboard1 from './components/ChartDashboard1'
import {ChartDashboard4} from './components/ChartDashboard4'
import {ContactsDashboard} from './components/ContactsDashboard'
import {Button} from '../report/context/types'
import {Get} from '../../modules/functions/get/Get'

interface InterfaceData {
    conversion: number
    numberVisit: number
    chartOptions: {
        series: number[]
        categoriesXAxis: string[]
    }
    cleverPhone: {
        has_cleverPhone: boolean;
        generalAppelRecu: number;
        click_sur_tel: number;
    }
    allFormsReceived: number
    announcement: {
        button: Button | null;
        icon: {
            src: string
            title: string
            text: string
        }[] | []
        image: string | null;
        sub_title: string;
        text: string;
        title: string;
    } | null
}

const DASHBOARD_CONVERSION = process.env.REACT_APP_DASHBOARD_CONVERSION || 'conversion'
const CALL_TRACKING = process.env.REACT_APP_CALL_TRACKING_GENERAL || 'calltraking-statistic'
const WEB_FORMS = process.env.REACT_APP_WEB_FORMS_HOME_WEB_FORMS || 'statistiques-generales'
const STATISTICS_VISIT = process.env.REACT_APP_STATISTICS_VISIT || 'statistics-visit'
const CALL_LOG = process.env.REACT_APP_CALL_TRACKING_CALL_LOG || 'calltraking-journal-appel'
const ANNOUNCEMENT = process.env.REACT_APP_DASHBOARD_ANNOUNCEMENT || 'v2/annonce-homepage'

const styles = {
    grid: {
        display: "grid",
        gridTemplateColumns: "repeat(5, 1fr)",
        gridTemplateRows: "1fr",
        gridGap: 10
    },
    gridMd: {
        gridTemplateColumns: "repeat(2, 1fr)",
    },
    gridSm: {
        gridTemplateColumns: "repeat(2, 1fr)",
    }
};

const DashboardPage: FC = () => {

    const productsStatus = useContext<ProductReturn[]>(ProductContext)

    const [rangeValue, setRangeValue] = React.useState<ValueType>([subDays(new Date(), 29), new Date()]),
        [loadingData, setLoadingData] = useState<'idle' | 'pending' | 'fulfilled' | 'rejected'>('idle'),
        [data, setData] = useState<InterfaceData>({
            conversion: 0,
            numberVisit: 0,
            chartOptions: {
                series: [],
                categoriesXAxis: [''],
            },
            cleverPhone: {
                has_cleverPhone: true,
                generalAppelRecu: 0,
                click_sur_tel: 0,
            },
            allFormsReceived: 0,
            announcement: null,
        }),
        [dateTimeStart, dateTimeEnd] = rangeValue,
        excludedNames = ['Mailing', 'Agenda', 'Ereputaion', 'Statistiques']

    const period = usePeriod(dateTimeStart, dateTimeEnd)

    const statusTrue = useMemo(
        () => productsStatus.filter(item =>
            item.status === 1 && !excludedNames.includes(item.name) || item.status === 0 && ['Calltraking'].includes(item.name),
        ),
        [productsStatus],
    )

    const onSuccessReq = (data: any, name: string) => {
        setData(prevData => {

            if (name === 'Calltraking') {
                return {...prevData, cleverPhone: data}
            } else if (name === 'Webformulaires') {
                return {...prevData, allFormsReceived: data}
            }
            return prevData
        })
        setLoadingData('fulfilled')
    }

    const onSuccessReq2 = (dataReq: any, name: string) => {

        setData(prevData => {
            if (name === 'conversion') {
                return {...prevData, conversion: dataReq}
            } else if (name === 'statistics_visit') {
                return {
                    ...prevData,
                    numberVisit: dataReq.seoNombreVisiteurs,
                    chartOptions: {
                        series: dataReq.seoChartVisiteurs.total,
                        categoriesXAxis: dataReq.seoChartVisiteurs.labels,
                    },
                }
            } else if (name === 'call_log') {
                return {
                    ...prevData,
                    callLog: dataReq,
                }
            } else if (name === 'announcement') {
                return {
                    ...prevData, announcement: dataReq.data.annonce_homepage_1,
                }
            } else {

                return {
                    ...prevData,
                }
            }
        })
        setLoadingData('fulfilled')
    }

    const onError = (error: any) => {
        console.log('query: ', {error})
    }

    const queries = useMemo(() => {
        return statusTrue.map(product => {
            const queryKey: QueryKey = [`dashboard_${product.name.toLowerCase()}`, period]
            const endpoint = product.name === 'Calltraking' ? CALL_TRACKING : WEB_FORMS

            return {
                queryKey,
                queryFn: (context: QueryFunctionContext<QueryKey, any>) => Get({endpoint, period}),
                enabled: !!period,
                select: (data: any) => (product.name === 'Calltraking' ? {
                    has_cleverPhone: data.has_cleverPhone,
                    click_sur_tel: data.click_sur_tel,
                    generalAppelRecu: data.generalAppelRecu,
                } : data.runningTotalFormReceived),
                onSuccess: (data: any) => onSuccessReq(data, product.name),
                onError,
            }
        })
    }, [productsStatus, period])

    const queries2 = useMemo(() => {
        return [
            {name: 'conversion', endpoint: DASHBOARD_CONVERSION},
            {name: 'statistics_visit', endpoint: STATISTICS_VISIT},
            {name: 'call_log', endpoint: CALL_LOG},
            {name: 'announcement', endpoint: ANNOUNCEMENT},
        ].map(query => {
            const queryKey: QueryKey = [`dashboard_${query.name.toLowerCase()}`, period]
            const endpoint = query.endpoint
            return {
                queryKey,
                queryFn: (context: QueryFunctionContext<QueryKey, any>) => Get({endpoint, period}),
                enabled: !!period,
                select: (data: any) => {
                    return data
                },
                onSuccess: (data: any) => onSuccessReq2(data, query.name),
                onError,
            }
        })
    }, [period])

    useQueries([...queries, ...queries2])

    const {innerWidth} = UseWindowSize()
    const getResponsiveStyles = () => {
        if (innerWidth <= 480) {
            return {...styles.grid, ...styles.gridSm}
        } else if (innerWidth <= 768) {
            return {...styles.grid, ...styles.gridMd}
        } else {
            return styles.grid
        }
    }

    return (
        <>

            {/* RangeValue */}
            <div className="row mb-8">
                <div className="col-12 d-flex justify-content-end align-items-center">

                    <DateRange rangeValue={rangeValue}
                               setRangeValue={setRangeValue}
                               loadingStatus={loadingData}
                               setLoadingStatus={(status) => setLoadingData(status)}
                               className="d-flex justify-content-end align-items-center"
                    />
                </div>
            </div>

            <div className="mb-5" style={getResponsiveStyles()}>
                <div>
                    <ChartDashboard1 bg={'#e1006d'}
                                     iconPath={'/media/icons/duotune/general/gen060.svg'}
                                     body={{total: data.numberVisit, text: 'Visites'}}
                                     to={'/statistiques/s-seo'}
                        // footer={{start: period.start, end: period.end}}
                    />
                </div>
                <div>
                    <ChartDashboard1 bg={'#0094f3'}
                                     iconPath={'/media/icons/duotune/arrows/arr029.svg'}
                                     body={{total: data.conversion, text: 'Conversions'}}
                        // footer={{start: period.start, end: period.end}}
                    />
                </div>
                <div>
                    <ChartDashboard1 bg={'#e1006d'}
                                     iconPath={'/media/icons/duotune/general/gen005.svg'}
                                     body={{total: data.allFormsReceived, text: 'Formulaires'}}
                                     to={'/web-formulaires/tableau-de-bord-wf'}
                        // footer={{start: period.start, end: period.end}}
                    />
                </div>
                <div>
                    <ChartDashboard1 bg={'#0094f3'}
                                     iconPath={"/media/icons/duotune/communication/com014.svg"}
                                     body={{total: data.cleverPhone.generalAppelRecu, text: "Appels"}}
                                     to={'/call-tracking/journal-appels'}
                    />
                </div>
                <div>
                    <ChartDashboard1 bg={'#e1006d'}
                                     iconPath={"/media/icons/duotune/coding/cod006.svg"}
                                     body={{total: data.cleverPhone.click_sur_tel, text: "Nombre de clics sur le téléphone"}}
                                     to={'/call-tracking/journal-appels'}
                    />
                </div>
            </div>

            <div className="row d-flex justify-content-center">
                <div className="col-xl-4 card card-xl-stretch">
                    <ContactsDashboard />
                </div>
                <div className="col-xl-8">
                    <ChartDashboard4 className={'card-xl-stretch'}
                                     chartOptions={data.chartOptions}
                    />
                </div>
            </div>


            <div className="row my-5">
                <div className="col-12">
                    {/*begin::Engage widget*/}
                    <div className="card h-lg-100"
                         style={{background: 'linear-gradient(112.14deg, rgb(12, 147, 225) 0%, rgb(225, 0, 109) 100%)'}}
                    >
                        {/*begin::body*/}
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-8 pe-0 mb-5 mb-sm-0">
                                    <div
                                        className="d-flex justify-content-between h-100 flex-column pt-xl-5 pb-xl-2 ps-xl-7">
                                        {/*begin::Container*/}
                                        <div className="mb-7">
                                            <div className="mb-6">
                                                <h3 className="fs-2x fw-semibold text-white">{data.announcement?.title}</h3>
                                                <span
                                                    className="fw-semibold text-white opacity-75">{data.announcement?.text}</span>
                                            </div>
                                            {/*begin::Items*/}
                                            <div className="d-flex align-items-center flex-wrap d-grid gap-2">

                                                {
                                                    data.announcement?.icon.map((item, index) => (
                                                        <div className="d-flex align-items-center me-5 me-xl-13"
                                                             key={index}>
                                                            <div className="symbol symbol-30px symbol-circle me-3">
                                                                <div className="symbol-label"
                                                                     style={{background: 'rgba(255, 255, 255, 0.15)'}}
                                                                >
                                                                    <img src={`${item.src}`}
                                                                         className="w-20px"
                                                                         alt="" />

                                                                    {/*<KTSVG*/}
                                                                    {/*    path="/media/icons/duotune/abstract/abs027.svg"*/}
                                                                    {/*    className="svg-icon svg-icon-3 svg-icon-white"/>*/}
                                                                </div>
                                                            </div>
                                                            <div className="m-0">
                                                                <a href="#"
                                                                   className="text-white opacity-75 fs-8">{item.title}</a>
                                                                <span
                                                                    className="fw-bold text-white fs-7 d-block">{item.text}</span>
                                                            </div>
                                                        </div>

                                                    ))
                                                }


                                                {/*/!*begin::Item*!/*/}
                                                {/*<div className="d-flex align-items-center me-5 me-xl-13">*/}
                                                {/*    <div className="symbol symbol-30px symbol-circle me-3">*/}
                                                {/*        <div className="symbol-label"*/}
                                                {/*             style={{background: 'rgba(255, 255, 255, 0.15)'}}*/}
                                                {/*        >*/}
                                                {/*            <KTSVG path="/media/icons/duotune/abstract/abs027.svg"*/}
                                                {/*                   className="svg-icon svg-icon-3 svg-icon-white"/>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*    <div className="m-0">*/}
                                                {/*        <a href="#" className="text-white opacity-75 fs-8">Clients</a>*/}
                                                {/*        <span className="fw-bold text-white fs-7 d-block">350</span>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*/!*end::Item*!/*/}
                                                {/*/!*begin::Item*!/*/}
                                                {/*<div className="d-flex align-items-center me-5 me-xl-13">*/}
                                                {/*    <div className="symbol symbol-30px symbol-circle me-3">*/}
                                                {/*        <div className="symbol-label"*/}
                                                {/*             style={{background: 'rgba(255, 255, 255, 0.15)'}}*/}
                                                {/*        >*/}
                                                {/*            <KTSVG path="/media/icons/duotune/abstract/abs027.svg"*/}
                                                {/*                   className="svg-icon svg-icon-3 svg-icon-white"/>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*    <div className="m-0">*/}
                                                {/*        <a href="#" className="text-white opacity-75 fs-8">Poste par mois</a>*/}
                                                {/*        <span className="fw-bold text-white fs-7 d-block">1200</span>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*/!*end::Item*!/*/}
                                                {/*/!*begin::Item*!/*/}
                                                {/*<div className="d-flex align-items-center">*/}
                                                {/*    <div className="symbol symbol-30px symbol-circle me-3">*/}
                                                {/*        <div className="symbol-label"*/}
                                                {/*             style={{background: 'rgba(255, 255, 255, 0.15)'}}*/}
                                                {/*        >*/}
                                                {/*            <KTSVG path="/media/icons/duotune/abstract/abs043.svg"*/}
                                                {/*                   className="svg-icon svg-icon-3 svg-icon-white"/>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*    <div className="m-0">*/}
                                                {/*        <a href="#" className="text-white opacity-75 fs-8">Conversion</a>*/}
                                                {/*        <span className="fw-bold text-white fs-7 d-block">58%</span>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*/!*end::Item*!/*/}
                                            </div>
                                            {/*end::Items*/}

                                        </div>
                                        {/*end::Container*/}
                                        {/*begin::Action*/}
                                        <div className="m-0">
                                            <a href={'mailto:support@webforce.be'}
                                               className="btn btn-color-white bg-white bg-opacity-15 bg-hover-opacity-25 fw-semibold"
                                            >
                                                En savoir plus
                                            </a>
                                        </div>
                                        {/*end::Action*/}
                                    </div>
                                </div>
                                <div className="col-4 d-flex justify-content-end">
                                    <img src={`${data.announcement?.image}`}
                                         alt=""
                                         className="w-300px" />
                                </div>
                            </div>
                        </div>
                        {/*begin::body*/}
                    </div>
                    {/*end::Engage widget*/}
                </div>
            </div>


            {/*begin::ROW-04 */
            }
            {/*card cursor */
            }
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-6">
                    <ChartsWidget7 className={'card-xl-stretch'} />
                </div>
                <div className="col-xl-6">
                    <ChartsWidget8 className={'card-xl-stretch'} />
                </div>
            </div>
            {/*end::ROW-04 */
            }


        </>
    )
}


const DashboardWrapper: FC = () => {

    const intl = useIntl()

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <DashboardPage />
        </>
    )
}

export {DashboardWrapper}