import {ChangeEvent, Dispatch, MutableRefObject, SetStateAction} from 'react'
import {ValueType} from 'rsuite/cjs/DateRangePicker/types'

export interface Ticket {
  id: number;
  created_at: string;
  description: string;
  product: string;
  count_message: number;
  status: string;
  subject: string;
  assign: string;
  attachment: Attachment[];
}

export interface Ticket_Status {
  id: number
  name: string;
  color: string;
}



export interface DataTickets {
  tickets: Ticket[] | null
}

export interface DataInterface {
  // tickets: Ticket[] | null
  statusArray: Ticket_Status[] | null
}


export interface SavContextInterface {
  data: DataInterface | undefined;
  setData: Dispatch<SetStateAction<DataInterface>>
  rangeValue: ValueType;
  setRangeValue: (value: ValueType) => void
  loadingStatus: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  setLoadingStatus: Dispatch<SetStateAction<'idle' | 'pending' | 'fulfilled' | 'rejected'>>
}
export interface responsesTicket extends Ticket{
  attachments: Attachment[];
  client_id: number
  created_at: string
  id: number
  message: "message" | "response"
  sav_ticket_id: number
  type: string
  user_id: number
}

export interface ApiResponse extends Status {
  tickets: Ticket[];
  statusArray: Ticket_Status[]
}
export interface ApiTicketResponse extends Status {
  ticket: Ticket;
}
export interface ApiResponsesTicket extends Status {
  messages: responsesTicket[];
}

interface Status {
  status: number;
  message_code: string;
}

export interface Attachment {
  src: string;
  type: string;
  size: string;
  name: string;
}


type HandleFileClickProps = {
  fileInputRef: MutableRefObject<HTMLInputElement | null>
}

export const HandleFileClick = ({fileInputRef}: HandleFileClickProps) => {
  if (fileInputRef.current) {
    fileInputRef.current.click()
  }
}

type HandleFileChangeProps = {
  e: ChangeEvent<HTMLInputElement>;
  formik: any;
  setFilesCount: Dispatch<SetStateAction<number>>;
}
export const HandleFileChange = ({e, formik, setFilesCount}: HandleFileChangeProps) => {
  const files = Array.from(e.target.files || []) as File[]
  if (files) {
    // Handle file upload logic here
    formik.setFieldValue('files', files)
    setFilesCount(files.length)
  }
}