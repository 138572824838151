import {Period} from '../../../pages/report/context/ReportProvider'
import SetupAxios from '../../../../setup/axios/SetupAxios'
import {API_URL, localStorageKey} from '../../all-pages'

interface GetInterface<T> {
    endpoint: string
    period: Period
    sortKey?: string
    sortOrder?: string
}

export async function Get<T>({endpoint, period, sortKey, sortOrder}: GetInterface<T>) {

    const {start, end} = period

    try {
        const params = new URLSearchParams({
            ...(period && {start, end}),
            ...(sortKey && {sortf: sortKey}),
            ...(sortOrder && {sorts: sortOrder})
        }).toString()

        const response = await SetupAxios.get<T>(`${API_URL}${endpoint}?${params}`)
        return response.data
    } catch (e) {
        window.localStorage.removeItem(localStorageKey)
        window.localStorage.removeItem('_userInterface')
        window.localStorage.removeItem('_clientCreationDate')
        window.location.reload();
        console.error('Error fetching call log data:', e);
    }
}